import React from "react";
import {Typography} from "@material-ui/core";
import { textStyle } from "./index";


const Subtitle2 = ({ children, textColor, textAlign, fontSize, fontFamily, ...rest }: any) => {
  rest.style = { ...rest?.style, ...textStyle(fontSize, fontFamily, textAlign, textColor) };

  return (
    <Typography variantMapping={{subtitle2: 'p'}} variant="subtitle2" {...rest}>
      {children}
    </Typography>
  );
};

export default Subtitle2;
