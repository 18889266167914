import React from 'react';
import dynamic from 'next/dynamic';
import { useAuth } from '../../lib/useAuth';
import { showEditIconBlue } from '../../server/constants';
import { Body1, Body2, Caption, H1, H2, H3, H4, H5, H6, Image, Overline, Subtitle1, Subtitle2 } from './Variants';

const Editor = dynamic(() => import('./Editor'));

export interface Content {
  blocks: [{
    key: string;
    text: string;
    type: string;
  }],
  entityMap: any;
  color: string;
  textAlign?: string;
  fontSize?: number;
  fontFamily?: string;
}

export interface EditorReadOnlyProps {
  sectionId?: string;
  content?: any;
  variant?: string;
  color?: string;
  align?: string;
  component?: any;
  src?: any;
  alt?: string;
  className?: string;
  bgImage?: boolean;
  comparison?: string;
  menuPageId?: number;
  menuContent?: string;
  refreshMenu?: string;
  upperDivClass?: string;
  style?: any;
}

function replaceString() {
  let replacementStringArr: string[] = [];
  const set = function (str: string) {
    replacementStringArr.push(str);
  }
  const get = function () {
    return replacementStringArr;
  }
  return {set, get};
}
export default function EditorReadOnly(props: EditorReadOnlyProps) {
  const {upperDivClass} = props
  const {user} = useAuth();
  const rSymbolRegex = /\#[\\R]+/g;
  const [content, setContent] = React.useState<Content>(() => {
    return typeof props.content === 'string' ? JSON.parse(props.content) : props.content;
  });
  const editable = user && (user.isLoggedIn || user.role == 4);
  React.useEffect(()=>{
    if(props.content && !content){
      setContent(typeof props.content === 'string' ? JSON.parse(props.content) : props.content);
    }
  },[props.content])
  if (editable) {
    return (
      <div className={upperDivClass || showEditIconBlue}>
        <Editor {...props}/>
      </div>
    );
  }
  const cbObj:{set: Function, get: Function} = replaceString();
  const entityMapObj = content && content.entityMap;

  const blockProps: any = {
    className: props.className,
    textColor: content?.color || "",
    textAlign: content?.textAlign,
    fontSize: content?.fontSize,
    fontFamily: content?.fontFamily,
    color: props.color || "",
    align: props.align || "",
    component: props.component || ""
  };

  const addLink = (text: string) => {
    const cbReturnArr = cbObj.get();
    cbReturnArr.length > 0 && cbReturnArr.forEach((item: string, index: number) => {
      text = text.replace(item, `<a href=${entityMapObj[ index ].data?.url} class="border-bottom-link" target="_blank">${item}</a>`);
    })
    return text;
  }
  const regexAnchorReplace = (text: any, block: any, content: any) => {
    if (block?.entityRanges.length === 0 && !text.match(rSymbolRegex)) {
      return text;
    }

    let isFormatted = false;
    let mutableText = text;
    if (Object.keys(content.entityMap).length > 0) { // ensure content.entityMap object is not empty
      isFormatted = true;
      mutableText = anchorTextReplace(mutableText, block, content);
      mutableText = addLink(mutableText)
    }

    const matched = mutableText.match(rSymbolRegex);
    if (matched) {
      isFormatted = true;
      mutableText = symbolReplace(mutableText)
    }

    return isFormatted
      ? <div dangerouslySetInnerHTML={{__html: mutableText}}/>
      : mutableText;
  }

  const anchorTextReplace = (text: any, block: any, content: any) => {
    if (block?.entityRanges.length === 0 && !text.match(rSymbolRegex)) {
      return text;
    }

    const empty = Object.keys(content.entityMap).length === 0;
    if (empty) {
      return text
    }

    block.entityRanges.forEach((item: any) => {
      const subString = text.slice(item.offset, item.offset + item.length);
      cbObj.set(subString)
    })
    return text;

  }

  const symbolReplace = (text: any) => {
    let items = text.split(rSymbolRegex);
    if (!items && items.length == 0) {
      return text;
    }

    let concatString = "";
    const result = items && items.length > 0 && items.map((item: string, index: number) => {
      const itemArr = item.split(" ");
      const lastWord = itemArr[ itemArr.length - 1 ];
      return index < items.length - 1
        ? `${item.replace(lastWord, `<span class="displayInlineFlex">${lastWord}<sup>®</sup></span>`)}`
        : item;
    })

    result.forEach((item: string) => {
      concatString = concatString.concat(item);
    });

    return concatString;
  }

  const getBlockProps = (blockIndex: number) => {
    const classesArray = blockProps?.className?.split(' ');
    const haveTitle = classesArray?.includes('title') || false;
    if (!haveTitle) {
      return {...blockProps};
    } else {
      if (blockIndex > 0) { /* remove title class in case of second occurrence of block */
        const index = classesArray.indexOf('title');
        if (index !== -1) {
          classesArray.splice(index, 1);
          blockProps.className = classesArray.join(" ");
        }
      }
      return {...blockProps};
    }
  }

  return (
    <>
      {
        ((content?.blocks && content?.blocks[ 0 ].type !== 'unordered-list-item' && content?.blocks) || [])
          .filter(block => !!block.type)
          .map((block, index) => {
            switch (block.type?.toUpperCase()) {
              case 'H1':
                return <H1
                  key={block.key} {...getBlockProps(index)} >{regexAnchorReplace(block.text, block, content)} </H1>;
              case 'H2':
                return <H2
                  key={block.key} {...getBlockProps(index)} >{regexAnchorReplace(block.text, block, content)} </H2>;
              case 'H3':
                return <H3
                  key={block.key} {...getBlockProps(index)} >{regexAnchorReplace(block.text, block, content)} </H3>;
              case 'H4':
                return <H4
                  key={block.key} {...getBlockProps(index)} >{regexAnchorReplace(block.text, block, content)} </H4>;
              case 'H5':
                return <H5
                  key={block.key} {...getBlockProps(index)} >{regexAnchorReplace(block.text, block, content)} </H5>;
              case 'H6':
                return <H6
                  key={block.key} {...getBlockProps(index)} >{regexAnchorReplace(block.text, block, content)} </H6>;
              case 'BODY1':
              case "UNSTYLED":
                return <Body1
                  key={block.key} {...getBlockProps(index)} >{regexAnchorReplace(block.text, block, content)} </Body1>;
              case 'BODY2':
                return <Body2
                  key={block.key} {...getBlockProps(index)} >{regexAnchorReplace(block.text, block, content)} </Body2>;
              case 'CAPTION':
                return <Caption
                  key={block.key} {...getBlockProps(index)}  >{regexAnchorReplace(block.text, block, content)}</Caption>;
              case 'OVERLINE':
                return <Overline
                  key={block.key} {...getBlockProps(index)}  >{regexAnchorReplace(block.text, block, content)}</Overline>;
              case 'SUBTITLE1':
                return <Subtitle1
                  key={block.key} {...getBlockProps(index)} >{regexAnchorReplace(block.text, block, content)} </Subtitle1>;
              case 'SUBTITLE2':
                return <Subtitle2
                  key={block.key} {...getBlockProps(index)} >{regexAnchorReplace(block.text, block, content)} </Subtitle2>;
              case 'IMAGEUPLOAD':
                return <Image key={block.key} src={props.src} className={props.className} alt="Selected Image"/>;

            }
          })
      }
      {
        (content?.blocks && content?.blocks[ 0 ].type === 'unordered-list-item' &&
          <ul className={"public-DraftStyleDefault-ul"}>
            {
              content?.blocks.filter((item) => !!item.text).map((block) => {
                return <li key={block.key}
                           className={"public-DraftStyleDefault-unorderedListItem public-DraftStyleDefault-reset public-DraftStyleDefault-depth0 public-DraftStyleDefault-listLTR"}>{block.text}</li>
              })
            }
          </ul>
        )
      }
    </>

  )
}
