import React from "react";
import { Typography } from "@material-ui/core";
import { textStyle } from "./index";


const Body1 = ({ children, textColor, textAlign, fontSize, fontFamily, ...rest }: any) => {
  rest.style = { ...rest?.style, ...textStyle(fontSize, fontFamily, textAlign, textColor) };
  return (
    <Typography variant="body1" {...rest}>
      {children}
    </Typography>
  );
};

export default Body1;
