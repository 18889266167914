import React from "react";
import { Typography } from "@material-ui/core";
import { textStyle } from "./index";

const Subtitle1 = ({children, textColor, textAlign, fontSize, fontFamily, ...rest}: any) => {
  rest.style = {...rest?.style, ...textStyle(fontSize, fontFamily, textAlign, textColor)};

  return (
    <Typography variantMapping={{subtitle1: 'p'}} variant="subtitle1" {...rest}>
      {children}
    </Typography>
  );
};

export default Subtitle1;
