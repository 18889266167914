import React from "react";
import {Typography} from "@material-ui/core";
import { textStyle } from "./index";


const H3 = ({ children, textColor, textAlign, fontSize, fontFamily, ...rest }: any) => {
    rest.style = { ...rest?.style, ...textStyle(fontSize, fontFamily, textAlign, textColor) };

    return (
        <Typography variant="h3" {...rest}>
            {children}
        </Typography>
    );
};

export default H3;
