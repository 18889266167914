import React from "react";
import { Typography } from "@material-ui/core";
import { textStyle } from "./index";


const Caption = ({ children, textColor, textAlign, fontSize, fontFamily, ...rest }: any) => {
  rest.style = { ...rest?.style, ...textStyle(fontSize, fontFamily, textAlign, textColor) };

  return (
    <Typography variant="caption" {...rest}>
      {children}
    </Typography>
  );
};

export default Caption;
