import H1 from './H1';
import H2 from './H2';
import H3 from './H3';
import H4 from './H4';
import H5 from './H5';
import H6 from './H6';

import Body from './Body';
import Body1 from './Body1';
import Body2 from './Body2';
import Caption from './Caption';
import Overline from './Overline';
import Subtitle1 from './Subtitle1';
import Subtitle2 from './Subtitle2';

import Image from './Image';

export {
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
    Body,
    Body1,
    Body2,
    Caption,
    Overline,
    Subtitle1,
    Subtitle2,
    Image
}

export function textStyle(fontSize: null | number | string, fontFamily: null | string, textAlign: null | string, textColor: null | string): any {
    const style: any = {};
    if (fontSize) {
        style.fontSize = `${fontSize}px`;
        style.lineHeight = 'normal';
    }

    if (fontFamily) {
        style.fontFamily = fontFamily
    }

    if (textAlign) {
        style.textAlign = textAlign;
    }

    if (textColor) {
        style.color = textColor;
    }

    return style;
}
